export default {
  state: {
    media: [],
    sortParams: {
      page: 1,
    },
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_MEDIA(state, media) {
      state.media = media;
    }
  },

  actions: {
    async addMedia(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/media/upload', formData);
    },

    async getMedia({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }
      }

      let url = '/panel/media';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_MEDIA', response.data);
        });
    },

    async removeMedia(_, mediaId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/media/delete', {
        id: mediaId
      });
    }
  },

  getters: {},

  namespaced: true,
};
