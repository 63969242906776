export default {
  state: {
    settings: [],
    visibleSettings: {
      temp_code_live_time: 'number',
      max_attempts_count: 'number',
      days_to_forced_reloging: 'number',
      terminal_source: 'string',
      merchant_model: 'string',
      daily_nomenclature_import_on: 'checkbox',
      minimal_delivery_amount: 'number',
      discount_on_pickup: 'number',
      daily_nomenclature_import_time: 'time',
      root_group: 'number',
      order_by_phone: 'phone',
      iiko_credentials: 'json',
      logging_to_database: 'checkbox',
      clear_log_after_n_days: 'number',
      minimum_time_to_cooking_self_delivery: 'number',
      terms_of_payment: 'string',
      max_auth_attempt_by_ip: 'number',
      date_and_time_from_cant_create_order: 'datetime',
      date_and_time_to_cant_create_order: 'datetime',
      recommendations_main_title: 'string',
      stories_seconds: 'number',
      percent_of_bonuses_to_order: 'number',
      organization_cant_order: 'string',
      main_page_meta_container: 'string'
    }
  },

  mutations: {
    SET_SETTINGS(state, settings) {
      const filteredSettings = [];
      const keysArray = Object.keys(state.visibleSettings);

      settings.forEach(item => {
        if (keysArray.includes(item.key)) {
          item.type = state.visibleSettings[item.key];
          filteredSettings.push(item);
        }
      });

      state.settings = filteredSettings;
    }
  },

  actions: {
    async editSettings(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/settings/update', formData);
    },

    async getSettings({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/settings')
        .then(response => {
          commit('SET_SETTINGS', response.data);
        });
    },
  },

  getters: {},

  namespaced: true,
};

