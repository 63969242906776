export default {
  state: {
    operators: [],
    selectedUser: null,
    sortParams: {
      page: 1,
      search: '',
    },
    users: [],
  },

  mutations: {
    SET_CURRENT_PAGE_PARAM(state, currentPage) {
      state.sortParams.page = currentPage;
    },

    SET_OPERATORS(state, operators) {
      state.operators = operators;
    },

    SET_SEARCH_PHRASE_PARAM(state, phrase) {
      state.sortParams.search = phrase;
    },

    SET_SELECTED_USER(state, user) {
      state.selectedUser = user;
    },

    SET_USERS(state, users) {
      state.users = users;
    },
  },

  actions: {
    async editUser(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/users/edit', formData);
    },

    async exportUsers({ state }) {
      let url = '/panel/users?export';

      if (state.sortParams.search) {
        url += `&search=${state.sortParams.search}`;
      }

      try {
        // eslint-disable-next-line no-undef
        const response = await axios.get(url);

        return response.data.url;
      } catch (error) {
        console.error(error);

        throw error;
      }
    },

    async getCodeForLoginAs(_, userId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/users/login', {
        id: userId
      });
    },

    async getOperators({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/get-operator-ids')
        .then((response) => {
          commit('SET_OPERATORS', response.data);
        });
    },

    async getUserById({ commit }, userId) {
      // eslint-disable-next-line no-undef
      await axios.get(`/panel/users/${userId}`)
        .then((response) => {
          commit('SET_SELECTED_USER', response.data);
        });
    },

    async getUsers({ commit, state }, payload = null) {
      if (!payload) {
        commit('SET_CURRENT_PAGE_PARAM', 1);
        commit('SET_SEARCH_PHRASE_PARAM', '');
      } else {
        if (payload.currentPage !== undefined) {
          commit('SET_CURRENT_PAGE_PARAM', payload.currentPage);
        }

        if (payload.searchPhrase !== undefined) {
          commit('SET_SEARCH_PHRASE_PARAM', payload.searchPhrase);
        }
      }

      let url = '/panel/users';

      for (let key in state.sortParams) {
        if (state.sortParams[key]) {
          url += `${url.indexOf('?') === -1 ? '?' : '&'}${key}=${state.sortParams[key]}`;
        }
      }

      // eslint-disable-next-line no-undef
      await axios.get(url)
        .then((response) => {
          commit('SET_USERS', response.data);
        });
    },

    async loginAs(_, formData) {
      try {
        // eslint-disable-next-line no-undef
        const response = await axios.post('/panel/users/login/confirm', formData);

        return response.data;
      } catch (error) {
        throw error;
      }
    },

    async removeUser(_, userId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/users/delete', {
        id: userId
      });
    },

    async toggleBlockUser(_, userId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/users/toggle-block', {
        id: userId
      });
    },
  },

  getters: {},

  namespaced: true,
};
