export default {
  state: {
    mailings: [],
  },

  mutations: {
    SET_MAILINGS(state, mailings) {
      state.mailings = mailings;
    }
  },

  actions: {
    async addMailing(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/store', formData);
    },

    async editMailing(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/update', formData);
    },

    async getMailings({ commit }) {
      // eslint-disable-next-line no-undef
      await axios.get('/panel/mailing-channels')
        .then(response => {
          commit('SET_MAILINGS', response.data);
        });
    },

    async removeMailing(_, mailingId) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/delete', {
        id: mailingId
      });
    },

    async sendMailing(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/send-mailing', formData);
    },

    async sendPush(_, formData) {
      // eslint-disable-next-line no-undef
      await axios.post('/panel/mailing-channels/send-push', formData);
    },
  },

  getters: {},

  namespaced: true,
};
