<template>
  <div class="view-auth d-flex justify-content-center align-items-center">
    <ValidationObserver
      ref="authFormObserver"
      v-slot="{ handleSubmit }"
      tag="b-card"
    >
      <b-form @submit.stop.prevent="handleSubmit(onFormSubmit)">
        <ValidationProvider
          v-slot="validationContext"
          name="Логин"
          rules="required"
        >
          <b-form-group label="Логин">
            <b-form-input
              v-model="login"
              aria-describedby="input-1-live-feedback"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback id="input-1-live-feedback">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <ValidationProvider
          v-slot="validationContext"
          name="Пароль"
          rules="required"
        >
          <b-form-group label="Пароль">
            <b-form-input
              v-model="password"
              aria-describedby="input-2-live-feedback"
              :state="getValidationState(validationContext)"
              type="password"
            />

            <b-form-invalid-feedback id="input-2-live-feedback">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </ValidationProvider>

        <b-button :disabled="isShowPreloader" type="submit" variant="primary">
          <b-spinner v-if="isShowPreloader" small />
          <span v-else>Войти</span>
        </b-button>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ViewAuth',

  data() {
    return {
      isShowPreloader: false,
      login: '',
      password: '',
    };
  },

  methods: {
    ...mapActions('auth', ['getInfo', 'signIn']),

    async onFormSubmit() {
      const isValid = await this.$refs.authFormObserver.validate();
      if (!isValid) {
        return;
      }

      this.isShowPreloader = true;
      try {
        await this.signIn({
          login: this.login,
          password: this.password
        });

        await this.getInfo();
        this.$toasts.add({
          message: 'Вход выполнен',
          variant: 'success'
        });
        this.$router.push('/');
      } catch (error) {
        console.error(error);

        this.$toasts.add({
          message: 'Неверный логин/пароль',
          variant: 'danger'
        });
      } finally {
        this.isShowPreloader = false;
      }
    },
  }
};
</script>