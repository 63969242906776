import Vue from 'vue';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import {
  required,
  email,
  min,
  min_value
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Заполните поле'
});

extend('email', {
  ...email,
  message: 'Введите корректный {_field_}'
});

extend('min', {
  ...min,
  message: '{_field_} не может быть короче {length} символов'
});

extend('min_value', {
  ...min_value,
  message: '{_field_} не может быть меньше {min}'
});

extend('required_obj', {
  params: ['objId'],
  validate: (value, { objId }) => {
    return Boolean(objId && objId !== 'null');
  },
  message:
    'Необходимо выбрать прикрепляемый объект'
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use({
  install(Vue) {
    Vue.mixin({
      methods: {
        getValidationState({ dirty, validated, valid = null }) {
          return dirty || validated ? valid : null;
        },
      }
    });
  }
});
