<template>
  <div class="view-users h-100">
    <b-overlay class="h-100" :show="isFetchingData">
      <div class="view-users__search row justify-content-between mb-3">
        <b-input-group class="col-4" prepend="Телефон:">
          <b-form-input v-model.trim="searchPhrase" />

          <b-input-group-append>
            <b-button variant="primary" @click="onSearchButtonClick">
              Поиск
            </b-button>
          </b-input-group-append>
        </b-input-group>

        <div class="col-auto">
          <b-button
            id="export-users-button"
            :disabled="!isHaveRights('users/export')"
            variant="success"
            @click="onExportUsersButtonClick"
          >
            Экспортировать
          </b-button>

          <b-tooltip
            v-if="!isHaveRights('users/export')"
            target="export-users-button"
          >
            У Вас недостаточно прав
          </b-tooltip>
        </div>
      </div>

      <b-table
        empty-filtered-text="По Вашему запросу ничего не найдено"
        empty-text="Список пуст"
        :fields="fields"
        hover
        :items="items"
        no-border-collapse
        no-local-sorting
        outlined
        show-empty
        sticky-header="calc(100% - 124px)"
        table-class="text-nowrap"
      >
        <template #cell(phone)="data">
          {{ data.item.phone | VMask("+# (###) ###-##-##") }}
        </template>

        <template #cell(blocked)="data">
          {{ data.item.blocked ? "Да" : "Нет" }}
        </template>

        <template #cell(newsletter)="data">
          {{ data.item.newsletter ? "Да" : "Нет" }}
        </template>

        <template #cell(actions)="data">
          <span :id="`show-card-user-button_${data.index}`">
            <b-button
              class="mr-2"
              :disabled="!isHaveRights('users/showCard')"
              size="sm"
              variant="outline-info"
              @click="onEditIconClick(data.item.id)"
            >
              <b-icon icon="pencil-fill" />
            </b-button>
          </span>

          <b-tooltip
            v-if="!isHaveRights('users/showCard')"
            :target="`show-card-user-button_${data.index}`"
          >
            У Вас недостаточно прав
          </b-tooltip>

          <span :id="`block-user-button_${data.index}`">
            <b-button
              class="mr-2"
              :disabled="!isHaveRights('users/block')"
              size="sm"
              :variant="
                data.item.blocked ? 'outline-success' : 'outline-warning'
              "
              @click="onBlockIconClick(data.item.id)"
            >
              <b-icon :icon="data.item.blocked ? 'unlock-fill' : 'lock-fill'" />
            </b-button>
          </span>

          <b-tooltip
            v-if="!isHaveRights('users/block')"
            :target="`block-user-button_${data.index}`"
          >
            У Вас недостаточно прав
          </b-tooltip>

          <span :id="`remove-user-button_${data.index}`">
            <b-button
              :disabled="!isHaveRights('users/remove')"
              size="sm"
              variant="outline-danger"
              @click="onRemoveIconClick(data.item.id)"
            >
              <b-icon icon="trash-fill" />
            </b-button>
          </span>

          <b-tooltip
            v-if="!isHaveRights('users/remove')"
            :target="`remove-user-button_${data.index}`"
          >
            У Вас недостаточно прав
          </b-tooltip>
        </template>
      </b-table>

      <b-pagination
        v-if="users.last_page > 1"
        :per-page="users.per_page"
        :total-rows="users.total"
        :value="users.current_page"
        @input="onPageChange"
      />
    </b-overlay>

    <ModalConfirm
      :is-visible="!!selectedForDeleteUserId"
      @hidden="onCloseConfirmModal"
      @confirm="onConfirmRemove"
    >
      <p>Удалить пользователя?</p>
    </ModalConfirm>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'ViewUsers',

  components: {
    ModalConfirm: () => import('@/components/Modal/Confirm'),
  },

  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'phone',
          label: 'Телефон',
        },
        {
          key: 'email',
          label: 'E-mail',
        },
        {
          key: 'blocked',
          label: 'Заблокирован',
        },
        {
          key: 'newsletter',
          label: 'Согласие на рассылку',
        },
        {
          key: 'actions',
          label: 'Действия',
        }
      ],
      isFetchingData: false,
      selectedForDeleteUserId: null,
    };
  },

  computed: {
    ...mapState('users', ['sortParams', 'users']),

    items() {
      return this.users.data?.length ? this.users.data.map(item => ({
        id: item.id,
        name: item.name || 'Не указано',
        phone: item.phone,
        email: item.email || 'Не указан',
        blocked: item.blocked,
        newsletter: item.newsletter,
      })) : [];
    },

    searchPhrase: {
      get: function () {
        return this.sortParams.phrase;
      },
      set: function (newValue) {
        this.setSearchPhrase(newValue.replace(/\D/g, ''));
      }
    },
  },

  async created() {
    await this.getData();

    this.$root.$on('modal:user-edit:hidden', async () => {
      await this.getData({
        currentPage: this.sortParams.page
      });
    });
  },

  methods: {
    ...mapActions('users', ['exportUsers', 'getUserById', 'getUsers', 'removeUser', 'toggleBlockUser']),
    ...mapMutations({
      setSearchPhrase: 'users/SET_SEARCH_PHRASE_PARAM',
    }),

    async getData(payload) {
      this.isFetchingData = true;

      try {
        if (!payload) {
          await this.getUsers();
        } else {
          await this.getUsers(payload);
        }
      } catch (error) {
        console.error(error);

        this.$toasts.add({
          message: error,
          variant: 'danger'
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    async onBlockIconClick(userId) {
      this.isFetchingData = true;

      try {
        await this.toggleBlockUser(userId);
        await this.getData({
          currentPage: this.sortParams.page,
        });
      } catch (error) {
        console.error(error);

        this.$toasts.add({
          message: error,
          variant: 'danger'
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    async onExportUsersButtonClick() {
      this.isFetchingData = true;

      try {
        const url = await this.exportUsers();
        window.open(url, '_blank');
      } catch (error) {
        this.$toasts.add({
          message: error,
          variant: 'danger'
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    onCloseConfirmModal() {
      this.selectedForDeleteUserId = null;
    },

    async onConfirmRemove() {
      this.isFetchingData = true;

      try {
        await this.removeUser(this.selectedForDeleteUserId);
        this.$toasts.add({
          message: 'Пользователь удален',
          variant: 'success'
        });
        await this.getData({
          currentPage: this.sortParams.page
        });
        this.onCloseConfirmModal();
      } catch (error) {
        console.error(error);

        this.$toasts.add({
          message: error,
          variant: 'danger'
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    async onEditIconClick(itemId) {
      this.isFetchingData = true;

      try {
        await this.getUserById(itemId);
      } catch (error) {
        console.error(error);

        this.$toasts.add({
          message: error,
          variant: 'danger'
        });
      } finally {
        this.isFetchingData = false;
      }
    },

    async onPageChange(page) {
      await this.getData({
        currentPage: page
      });
    },

    onRemoveIconClick(itemId) {
      this.selectedForDeleteUserId = itemId;
    },

    async onSearchButtonClick() {
      await this.getData({
        currentPage: 1
      });
    },
  }
};
</script>

<style lang="scss" scoped></style>